export const MoscowCenterCoords: [number, number] = [55.8203756, 37.4431069];

export const moscowCity = {
  cdek_id: 44,
  country: 'Россия',
  country_code: 'ru',
  fullname: 'Москва, Москва, Россия',
  id: 14895,
  locale: 'ru-RU',
  metro: false,
  name: 'Москва',
  url: 'moscow',
};
