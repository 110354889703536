import { useStore } from 'vuex';
import MainState from '@/types/store';
import { computed, inject } from 'vue';
import { CdekDefaultCity } from '@/types/dto/city';
import { COOKIE_CITY_ID_TAG } from '@/constants/cookie-tag';
import { VueCookies } from 'vue-cookies';
import { useOfficeDataService } from '@/services/OfficeDataService';

export const useSelectedCity = () => {
  const officeService = useOfficeDataService();

  const $cookies = inject<VueCookies>('$cookies');

  const store = useStore<MainState>();

  const globalCity = computed(() => store.state.city);

  const selectGlobalCity = (city: CdekDefaultCity) => {
    store.dispatch('SELECT_CITY', city);
    $cookies?.set(COOKIE_CITY_ID_TAG, city.cdek_id);
    officeService.city = city;
  };

  return {
    globalCity,
    selectGlobalCity,
  };
};
