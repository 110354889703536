/**
 * Calculate the center/average of multiple GeoLocation coordinates
 * Expects an array of objects with .latitude and .longitude properties
 *
 * @url http://stackoverflow.com/a/14231286/538646
 * @url https://gist.github.com/tlhunter/0ea604b77775b3e7d7d25ea0f70a23eb
 */
interface GeolocationData {
  geoLatitude: string;
  geoLongitude: string;
}

export function averageGeolocation(offices: GeolocationData[]): [number, number] {
  if (offices.length === 1) {
    return [+offices[0].geoLatitude, +offices[0].geoLongitude];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (const coord of offices) {
    const latitude = (+coord.geoLatitude * Math.PI) / 180;
    const longitude = (+coord.geoLongitude * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = offices.length;

  x /= total;
  y /= total;
  z /= total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return [(centralLatitude * 180) / Math.PI, (centralLongitude * 180) / Math.PI];
}
